import { http } from "./axios";

export class InvoiceSettingService {
    
  public static getInvoiceSettings(siteId:number,params:any=null):any {
    return http.get(`/invoice-settings/${siteId}`,{params});
  }

  public static saveInvoiceSetting(data: any) {
    if(data?.id){
      return http.put(`/invoice-settings/${data.id}`, data);
    }
    return http.post("/invoice-settings", data);
  }
}
