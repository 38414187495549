import React from "react";
const color: {[key:string]:string} = {
  PENDING: "#FFB74D",
  ACCEPTED: "#B9FBC0",
  REFUSED: "#FF2C2C",
};

const title: {[key:string]:string} = {
    PENDING: "DEMANDE D'ANNULATION EN COURS",
    ACCEPTED: "DEMANDE D'ANNULATION ACCEPTÉE",
    REFUSED: "REFUSÉE",
};

export function CancelationStatus({ status }: { status: string }) {
  return (
    <div
      title={title[status]}
      style={{
        backgroundColor: color[status],
        height: "15px",
        width: "15px",
        borderRadius: "100%",
        cursor:'pointer'
      }}
    />
  );
}
