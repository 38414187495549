import { http } from './axios';

export class MailSettingService {
	public static create(data:{mails:string[],siteId:number}):Promise<any>{
		return http.post(`/mail-settings`, data);
	}

	public static getMailSettings(siteId:number):Promise<string[]>{
		return http.get(`/mail-settings/${siteId}`);
	}
}
