import { FetchResponse } from '../models/fetch-response-model';
import { http } from './axios';

export class UserService {
	public static getUserPerSite(siteId: number): Promise<FetchResponse> {
		return http.get(`/user-manager/?site=${siteId}`);
	}

	public static getUserTypes(): Promise<FetchResponse> {
		return http.get(`/users/types`);
	}

	public static save(data: any) {
		if (data?.id) {
			return http.put(`/users/${data.id}`, data);
		}
		return http.post("/users", data);
	}

	public static changePassword(data: any): Promise<FetchResponse> {
		return http.put(`/users/password`, data);
	}

	public static getUser(login: string = ""): Promise<FetchResponse> {
		return http.get(`/users/user?login=${login}`);
	}

	public static getCashiers(siteId:number): Promise<any[]> {
		return http.get(`/users/cashiers/${siteId}`);
	}
}
