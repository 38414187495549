import { FetchResponse } from '../models';
import { http } from './axios';

export class ReportService {
	public static getReportPerDistrict(fromDate: string = "", toDate: string = "", site: number = 0): Promise<FetchResponse> {
		return http.get(`/reports/district?fromDate=${fromDate}&toDate=${toDate}&site=${site}`);
	}

	public static getReportPerUser(fromDate: string = "", toDate: string = "", site: number = 0): Promise<FetchResponse> {
		return http.get(`/reports/user?fromDate=${fromDate}&toDate=${toDate}&site=${site}`);
	}

	public static getReportDetails(fromDate: string = "", toDate: string = "", site: number = 0): Promise<FetchResponse> {
		return http.get(`/reports/details?fromDate=${fromDate}&toDate=${toDate}&site=${site}`);
	}

	public static getReportById(reportId:number,siteId:number,params={}): Promise<any> {
		return http.get(`/reports/${reportId}/${siteId}`,{params});
	}

	public static updateReport(payload:any): Promise<any> {
		return http.put(`/reports/${payload.id}/${payload.siteId}`,payload);
	}

	public static getReportsBySiteId(siteId:number,params={}): Promise<any> {
		return http.get(`/reports/${siteId}`,{params});
	}

	public static updateReports(payload:any): Promise<any> {
		return http.put(`/reports/${payload.siteId}`,payload);
	}
}