import { FC, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useStore } from "../store";
import { Spin } from "antd";
import RootPage from "../pages/root";
import AdminDashboard from "../pages/admin/dashboard";
import UserSitePage from "../pages/manager/site";
import CustomerPage from "../pages/manager/customer";
import MailSetting from "../pages/admin/MailSetting";
import DashboardPage from "../pages/manager/dashboard";
import UserManagerPage from "../pages/admin/usermanager";
import { UserProfilePage } from "../pages/profile";

const HomePage = lazy(() => import("../pages/manager/report"));
const LoginPage = lazy(() => import("../pages/login"));
const AdminPrices = lazy(() => import("../pages/admin/Prices"));
const CashierTabs = lazy(() => import("../pages/cashier/Tabs"));
const ManagerTabs = lazy(() => import("../pages/manager/invoices/Tabs"));
const VolumeProducedDistributedInitialIndex = lazy(() => import("../pages/manager/VolumeProducedDistributedInitialIndex"));

const AppRoutes: FC = () => {
	const { state: { loggedIn, checkTokenLoading, user } } = useStore();

	if (checkTokenLoading || (localStorage.getItem('token') && (!loggedIn || !user))) {
		return <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
			<Spin size="large" />
		</div>
	}

	if (user?.type === 'admin' && loggedIn) {
		return (
			<RootPage>
				<Routes>
					<Route path="/" element={<Private loggedIn={loggedIn}><AdminDashboard /></Private>} />
					<Route path="/tarifs" element={<Private loggedIn={loggedIn}><AdminPrices /></Private>} />
					<Route path="/parametres" element={<Private loggedIn={loggedIn}><MailSetting /></Private>} />
					<Route path="/user-manager" element={<Private loggedIn={loggedIn}><UserManagerPage /></Private>} />
					<Route path="/profile" element={<Private loggedIn={loggedIn}><UserProfilePage /></Private>} />
					<Route path='*' element={<Navigate to="/" />} />
				</Routes>
			</RootPage>
		);
	}

	if (user?.type === 'manager' && loggedIn) {
		return (
			<RootPage>
				<Routes>
					<Route path="/" element={<Private loggedIn={loggedIn}><HomePage /></Private>} />
					<Route path="/sites" element={<Private loggedIn={loggedIn}><UserSitePage /></Private>} />
					<Route path="/customer" element={<Private loggedIn={loggedIn}><CustomerPage /></Private>} />
					<Route path="/dashboard" element={<Private loggedIn={loggedIn}><DashboardPage /></Private>} />
					<Route path="/invoices" element={<Private loggedIn={loggedIn}><ManagerTabs /></Private>} />
					<Route path="/profile" element={<Private loggedIn={loggedIn}><UserProfilePage /></Private>} />
					<Route path="/parametrage/index-initiaux" element={<Private loggedIn={loggedIn}><VolumeProducedDistributedInitialIndex /></Private>} />
					<Route path='*' element={<Navigate to="/" />} />
				</Routes>
			</RootPage>
		);
	}
	
	if (user?.type === 'cashier' && loggedIn) {
		return (
			<RootPage>
				<Routes>
					<Route path="/invoices" element={<Private loggedIn={loggedIn}><CashierTabs /></Private>} />
					<Route path="/profile" element={<Private loggedIn={loggedIn}><UserProfilePage /></Private>} />
					<Route path='*' element={<Navigate to="/invoices" />} />
				</Routes>
			</RootPage>
		);
	}

	return (
		<Routes>
			<Route path="/auth/login" element={<Public loggedIn={loggedIn}><LoginPage /></Public>} />
			<Route path="" element={<Navigate to="/auth/login" />} />
			<Route path='*' element={<Navigate to="/auth/login" />} />
		</Routes>
	);
};

export function Private({ children, loggedIn }: any) {
	if (loggedIn) {
		return children;
	}
	return <Navigate to="/" />;
}

export function Public({ children, loggedIn }: any) {
	if (!loggedIn) {
		return children;
	}
	return <Navigate to="/" />;
}

export default AppRoutes;