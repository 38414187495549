import { FetchResponse } from '../models/fetch-response-model';
import { http } from './axios';

export class CustomerService {
	public static getCustomerDetails(): Promise<FetchResponse> {
		return http.get('/customers/details');
	}

	public static getCustomerPerSite(siteId: number): Promise<FetchResponse> {
		return http.get(`/customers/details/site/${siteId}`);
	}

	public static importCustomer(data: FormData): Promise<FetchResponse> {
		return http.post(`/customers/import`, data);
	}
}
