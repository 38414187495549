import { FC, useEffect, useState } from "react";
import { Row, Form, Select, Button, Space, FormProps } from "antd";
import { FetchResponse, Site } from "../../../models";
import { SiteService } from "../../../services";

const UserSitePage: FC = () => {
	const [sites, setSites] = useState<Site[]>([]);
	const [defaultSite, setDefaultSite] = useState<number>();

	useEffect(() => {
		const fetch = async () => {
			let result: FetchResponse = await SiteService.getUserSites();
			if (result?.status === "success") {
				const sites: Site[] = result.data;
				if (sites?.length > 0) {
					const d: Site[] = sites.filter((item) => item.defaultSite);
					if (d?.length > 0) {
						setDefaultSite(d[0].id);
					} else {
						setDefaultSite(-1);
					}
					setSites(result.data);
				} else {
					setSites([]);
				}
			}
		};
		fetch();
	}, []);

	const onFinish: FormProps<FormType>["onFinish"] = async (data) => {
		try {
			const result: any = await SiteService.setDefaultSite({ siteId: data.site });
			if (result.status === "success") {
				setDefaultSite(sites.filter((item) => item.id === data.site)[0].id);
			}
		} catch (e) {
			console.info("saving site error: ", e);
		}
	}

	return (
		<Space direction="vertical" size={"large"} style={{ display: 'flex' }}>
			<Row justify={"center"}>
				{defaultSite && <Form
					name="sites"
					onFinish={onFinish}
					layout="inline"
				>
					<Form.Item
						label="Site par défaut"
						name={"site"}
						initialValue={defaultSite}
						rules={[{ required: true, message: 'Veuillez choisir un site' }]}
					>
						<Select
							showSearch
							options={sites}
							fieldNames={{
								label: "label",
								value: "id",
							}}
							key={"id"}
							style={{ width: 200 }}
							placeholder="Tous"
							optionFilterProp="children"
							filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
							filterSort={(optionA, optionB) =>
								(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
							}
						/>
					</Form.Item>
					<Form.Item wrapperCol={{ span: 4, offset: 4 }} >
						<Button type="primary" htmlType="submit">Valider</Button>
					</Form.Item>
				</Form>}
			</Row>
		</Space>
	)
}

class FormType {
	site: number = 0;
}

export default UserSitePage;
