export class CustomerDetails {
	id: number = 0;
	lastName: string = '';
	firstName: string = '';
	address: string = '';
	districtId: number = 0;
	district: string = '';
	site: string = '';
	meter: string = '';
	siteId: number = 0;
	siteUserId: number = 0;
	createdDate: Date = new Date();
	branchType: string = '';
}