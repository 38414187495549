import { FC, useCallback, useEffect, useMemo, useState } from "react";
import dayjs from 'dayjs';
import { Col, Row, DatePicker, Form, Space, FormProps, TimeRangePickerProps, message, Spin, Flex, Progress } from "antd";
import { FetchResponse } from "../../../models";
import moment from "moment";
import { useStore } from "../../../store";
import CanvasJSReact from '@canvasjs/react-charts';
import { DashboardService } from "../../../services";
import { InnerCard } from "../../../components";
import { AreaChartOutlined, PieChartOutlined, StockOutlined } from "@ant-design/icons";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const { RangePicker } = DatePicker;

const defaultDate: number = -365;

const DashboardPage: FC = () => {
	// const [reportState, setReportState] = useState<{ [key: string]: any[] }>({});
	const [subscribers, setSubscribers] = useState<{ [key: string]: any[] }>({ "menage": [{ y: 0 }] });
	const [volumeTotal, setVolumeTotal] = useState<{ [key: string]: any[] }>({});
	const [dataProspect, setDataProspect] = useState<{ [key: string]: number }>({});
	const [customerFilter, setCustomerFilter] = useState<FilterType>(Object.assign(new FilterType(), { periode: [dayjs().add(defaultDate, 'd'), dayjs()] }));
	const [volumeFilter, setVolumeFilter] = useState<FilterType>(Object.assign(new FilterType(), { periode: [dayjs().add(defaultDate, 'd'), dayjs()] }));
	const { state: { currentSite } } = useStore();
	const [messageApi, contextHolder] = message.useMessage();
	const [loading, setLoading] = useState<boolean>(false);
	const [toogleCustomer, setToogleCustomer] = useState<boolean>(true);

	const getRangeDate = useCallback((filter: FilterType) => {
		let fromDate: string = "";
		let toDate: string = "";
		try {
			if (filter.periode) {
				if (filter.periode[0]) {
					fromDate = filter.periode[0] ? moment(filter.periode[0].$d).format('YYYY-MM-DD') : '';
				}
				if (filter.periode[1]) {
					toDate = filter.periode[1] ? moment(filter.periode[1].$d).format('YYYY-MM-DD') : '';
				}
			}
		} catch (e: any) {
			fromDate = moment(dayjs().add(defaultDate, 'd').toString()).format('YYYY-MM-DD');
			toDate = moment(dayjs().toString()).format('YYYY-MM-DD');
		}
		return [fromDate, toDate];
	}, []);

	useEffect(() => {
		const fetch = async () => {
			try {
				let [fromDateCustomer, toDateCustomer]: string[] = getRangeDate(customerFilter);
				if (currentSite.id !== 0) {
					let result: FetchResponse;
					setLoading(true);
					// Nombre d'abonnees
					result = await DashboardService.getSubscribers(fromDateCustomer, toDateCustomer, currentSite.id);
					if (result?.status === "success") {
						// console.log("res data", result.data);
						setSubscribers(result.data);
					}
				} else {
					// messageApi.open({
					// 	type: 'error',
					// 	content: 'Erreur de la recuperation du site',
					// });
				}
			} catch (e: any) {
				console.log("Fetch data customer: ", e.message);
			} finally {
				setLoading(false);
			}
		};
		fetch();
	}, [currentSite.id, messageApi, getRangeDate, customerFilter]);

	useEffect(() => {
		const fetch = async () => {
			try {
				if (currentSite.id !== 0) {
					let result: FetchResponse;
					setLoading(true);
					// Nombre d'abonnees et prospect total
					result = await DashboardService.getCustomerAndProspect(currentSite.id);
					if (result?.status === "success") {
						setDataProspect(result.data);
					}
				} else {
					// messageApi.open({
					// 	type: 'error',
					// 	content: 'Erreur de la recuperation du site',
					// });
				}
			} catch (e: any) {
				console.log("Fetch data customer: ", e.message);
			} finally {
				setLoading(false);
			}
		};
		fetch();
	}, [currentSite.id, messageApi]);

	const percentCustomer: number[] = useMemo(() => {
		if (dataProspect.menage === 0) {
			return [0, 0];
		}
		const customer: number = +(100 * dataProspect.customer / dataProspect.menage).toFixed(1);
		const prospect: number = +(100 * dataProspect.prospect / dataProspect.menage).toFixed(1);
		return [customer, prospect];
	}, [dataProspect])

	useEffect(() => {
		const fetch = async () => {
			try {
				let [fromDateVolume, toDateVolume]: string[] = getRangeDate(volumeFilter);
				if (currentSite.id !== 0) {
					let result: FetchResponse;
					setLoading(true);
					// Volume total
					result = await DashboardService.getVolumeTotal(fromDateVolume, toDateVolume, currentSite.id);
					if (result?.status === "success") {
						// console.log(result.data);
						setVolumeTotal(result.data);
					}
				} else {
					// messageApi.open({
					// 	type: 'error',
					// 	content: 'Erreur de la recuperation du site',
					// });
				}
			} catch (e: any) {
				console.log("Fetch volume data: ", e.message);
			} finally {
				setLoading(false);
			}
		};
		fetch();
	}, [currentSite.id, messageApi, getRangeDate, volumeFilter]);

	const onCustomerFilterChange: FormProps<FilterType>["onValuesChange"] = async (_, filter) => {
		try {
			setCustomerFilter(filter);
		} catch (e) {
			console.info("Customer filter changed error: ", e);
		}
	};

	const onVolumeFilterChange: FormProps<FilterType>["onValuesChange"] = async (_, filter) => {
		try {
			setVolumeFilter(filter);
		} catch (e) {
			console.info("Volume filter changed error: ", e);
		}
	};

	const totalConsumedOpt = {
		theme: "light2",
		title: {
			text: "Volume produit - distribué",
			fontSize: 24,
		},
		subtitles: [{
			text: "Volume facturé - encaissé"
		}],
		toolTip: {
			shared: true
		},
		axisY: {
			suffix: " m³"
		},
		data: [
			{
				type: "spline",
				name: "Volume produit",
				showInLegend: true,
				yValueFormatString: "#,##0 m³",
				dataPoints: volumeTotal["produit"]
			},
			{
				type: "spline",
				name: "Volume distribué",
				showInLegend: true,
				yValueFormatString: "#,##0 m³",
				dataPoints: volumeTotal["distribue"]
			},
			{
				type: "spline",
				name: "Volume facturé",
				showInLegend: true,
				yValueFormatString: "#,##0 m³",
				dataPoints: volumeTotal["facture"]
			},
			{
				type: "spline",
				name: "Volume encaissé",
				showInLegend: true,
				yValueFormatString: "#,##0 m³",
				dataPoints: volumeTotal["releve"]
			},
		]
	}

	const subscriberOpt = {
		theme: "light2",
		title: {
			text: "Nombre de clients",
			fontSize: 24,
		},
		subtitles: [{
			text: "Nombre de ménages"
		}],
		toolTip: {
			shared: true
		},
		data: [
			{
				type: "spline",
				name: "Nombre de ménages",
				showInLegend: true,
				yValueFormatString: "#,##0",
				dataPoints: subscribers["menage"]
			},
			{
				type: "splineArea",
				name: "Nombre de clients",
				showInLegend: true,
				yValueFormatString: "#,##0",
				dataPoints: subscribers["abonne"]
			}
		]
	};

	return (
		<Spin size="large" spinning={loading}>
			<Space direction="vertical" size={"small"} style={{ display: 'flex' }}>
				{contextHolder}
				<InnerCard
					title={"Clients"}
					icon={toogleCustomer === false ? <AreaChartOutlined /> : <PieChartOutlined />}
					extra={
						<div className="customer-db" onClick={() => { setToogleCustomer(!toogleCustomer) }}>
							{toogleCustomer === true ? <AreaChartOutlined /> : <PieChartOutlined />}
						</div>
					}
				>
					{toogleCustomer ? (
						<div>
							{isNaN(percentCustomer[0]) == false && isNaN(percentCustomer[1]) == false && (percentCustomer[0] + percentCustomer[1] !== 0) ?
								(<Row gutter={24} justify={"center"} align={'middle'} style={{ height: '433px' }}>
									<Col span={8} style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										flexDirection: 'column',
									}}>
										<Progress
											percent={percentCustomer[0]}
											format={() => `${percentCustomer[0]}%`}
											success={{ percent: percentCustomer[0] }}
											type="dashboard"
											size={300}
											strokeWidth={10}
										/>
										<div style={{ fontSize: 20, textAlign: 'center', width: '100%' }}>Clients</div>
									</Col>
									<Col span={8} style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										flexDirection: 'column',
									}}>
										<Progress
											percent={percentCustomer[1]}
											format={() => `${percentCustomer[1]}%`}
											type="dashboard"
											size={300}
											strokeWidth={10}
											status="exception"
										/>
										<div style={{ fontSize: 20, textAlign: 'center', width: '100%' }}>Prospect</div>
									</Col>
								</Row>)
								: (
									<Row justify={"center"}>
										<Col span={24}>
											<Flex className="empty-data" justify={"center"} align={"center"}>
												<span style={{ width: '50%', textAlign: 'center' }}><b> Aucun enregistrement</b> trouvé</span>
											</Flex>
										</Col>
									</Row>
								)
							}
						</div>
					) : (
							<div>
								{isNaN(percentCustomer[0]) == false && isNaN(percentCustomer[1]) == false && (percentCustomer[0] + percentCustomer[1] !== 0) ?
									(<Form
									name="customer_filter"
									onValuesChange={onCustomerFilterChange}
									style={{ maxWidth: 'none' }}
								>
									<Row gutter={24} justify={"end"}>
										<Col span={8}>
											<Form.Item
												label=""
												name={"periode"}
												initialValue={[dayjs().add(defaultDate, 'd'), dayjs()]}
												style={{ width: '100%', margin: 0 }}
											>
												<RangePicker
													style={{ width: '100%' }}
													picker="month"
													presets={rangePresets}
													placeholder={["01-01-1970", "Aujourd'hui"]}
													needConfirm={true}
													allowEmpty={[false, true]}
													name="periode"
													format={dateFormat}
												/>
											</Form.Item>
										</Col>
									</Row>
									</Form>) : <></>
								}
								<Row justify={"center"}>
									<Col span={24}>
										{isNaN(percentCustomer[0]) == false && isNaN(percentCustomer[1]) == false && (percentCustomer[0] + percentCustomer[1] !== 0) ?
											<CanvasJSChart options={subscriberOpt} onClick={() => { setToogleCustomer(!toogleCustomer) }} />
											:
											(<Flex className="empty-data" justify={"center"} align={"center"}>
												<span style={{ width: '50%', textAlign: 'center' }}>Pour afficher le nombre des abonnés, veuillez contacter votre <b>administrateur</b> afin de renseigner le <b>nombre total de ménage</b> sur le site</span>
											</Flex>)
										}
									</Col>
								</Row>
						</div>
					)}

				</InnerCard>
				<InnerCard
					title={"Volume"}
					icon={<StockOutlined />}
				>
					<Form
						name="volume_filter"
						onValuesChange={onVolumeFilterChange}
						style={{ maxWidth: 'none' }}
					>
						<Row gutter={24} justify={"end"}>
							<Col span={8}>
								<Form.Item
									label=""
									name={"periode"}
									initialValue={[dayjs().add(defaultDate, 'd'), dayjs()]}
									style={{ width: '100%', margin: 0 }}
								>
									<RangePicker
										style={{ width: '100%' }}
										picker="month"
										presets={rangePresets}
										placeholder={["01-01-1970", "Aujourd'hui"]}
										needConfirm={true}
										allowEmpty={[false, true]}
										name="periode"
										format={dateFormat}
									/>
								</Form.Item>
							</Col>
						</Row>
					</Form>
					<Row justify={"center"}>
						{/* <Col span={12} style={{ paddingRight: '10px' }}>
						{customerPerDistrict.length ? <CanvasJSChart options={totalPerDistrictOpt} /> : <></>}
					</Col> */}
						<Col span={24}>
							<CanvasJSChart options={totalConsumedOpt} />
						</Col>
					</Row>
				</InnerCard>
			</Space>
		</Spin>
	)
};

const rangePresets: TimeRangePickerProps['presets'] = [
	{ label: 'Mois actuel', value: [dayjs().startOf('month'), dayjs().endOf('month')] },
	{ label: '3 dernier mois', value: [dayjs().add(-2, 'M'), dayjs()] },
	{ label: '6 dernier mois', value: [dayjs().add(-5, 'M'), dayjs()] },
	{ label: '12 dernier mois', value: [dayjs().add(-11, 'M'), dayjs()] },
];

const dateFormat = 'MMMM YYYY';

class FilterType {
	periode: any[] = [];
}

export default DashboardPage;