export class VolumeProducedDistributed {
  id: number = 0;
  startDate!: string;
  endDate!: string;
  indexDistributed!: string;
  indexProduced!: string;
  default!: boolean;
  siteId!: number;
  periode!:{startDate: string, endDate: string}
}
