import { FetchResponse } from '../models';
import { http } from './axios';

export class DashboardService {
	public static getCustomersPerDistrict(fromDate: string = "", toDate: string = "", site: number = 0): Promise<FetchResponse> {
		return http.get(`/dashboard/customersperdistrict?fromDate=${fromDate}&toDate=${toDate}&site=${site}`);
	}

	public static getReportState(fromDate: string = "", toDate: string = "", site: number = 0): Promise<FetchResponse> {
		return http.get(`/dashboard/reportstate?fromDate=${fromDate}&toDate=${toDate}&site=${site}`);
	}

	public static getVolumeTotal(fromDate: string = "", toDate: string = "", site: number = 0): Promise<FetchResponse> {
		return http.get(`/dashboard/volumetotal?fromDate=${fromDate}&toDate=${toDate}&site=${site}`);
	}

	public static getSubscribers(fromDate: string = "", toDate: string = "", site: number = 0): Promise<FetchResponse> {
		return http.get(`/dashboard/abonnee?fromDate=${fromDate}&toDate=${toDate}&site=${site}`);
	}
}