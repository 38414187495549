import React, { useEffect, useMemo, useState } from "react";
import { Modal, Form, Input, notification, Select, Radio, Button, Flex, RadioChangeEvent, message, Alert, } from "antd";
import { UserService } from "../../../services";
import { FetchResponse, Site, UserDetails } from "../../../models";
import { useStore } from "../../../store";
import { ExclamationCircleFilled } from "@ant-design/icons";

interface ITypeProps {
	show: boolean;
	currentUser: UserDetails;
	userSites: Site[],
	roles: { label: string, value: string, name: string }[];
	onOk: () => void;
	onCancel: () => void;
}

const formItemLayout = {
	wrapperCol: {
		xs: { span: 14 },
		sm: { span: 14 },
	},
	labelCol: {
		xs: { span: 8 },
		sm: { span: 8 },
	},
};

export const UserForm: React.FC<ITypeProps> = ({ show, currentUser, userSites, roles, onOk, onCancel }) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const { state: { currentSite } } = useStore();
	const [currentRole, setCurrentRole] = useState<string>("");

	const sites = useMemo(() => {
		return userSites.map((item: Site) => {
			return { label: item.label, value: item.id, disabled: item.id === currentSite.id ? true : false };
		})
	}, [currentSite.id, userSites]);

	useEffect(() => {
		setCurrentRole(roles[0].value);
	}, []);

	const onChangeRole = ({ target: { value } }: RadioChangeEvent) => {
		setCurrentRole(value);
	};

	const onFinishFailed = (data: any) => {
		console.log("Failed ", data);
	};

	const { confirm } = Modal;

	const showConfirm = async () => {
		confirm({
			title: `Confirmation`,
			icon: <ExclamationCircleFilled />,
			content: (
				<span>
					Etes-vous sûr de vouloir ajouter l'utilisateur <b>{form.getFieldsValue().login}</b> en tant que <b>{roles.find(item => item.value === form.getFieldsValue().role)?.label}</b> ?
				</span>
			),
			okText: `Oui`,
			cancelText: 'Non',
			onOk: async () => {
				try {
					const values = { ...form.getFieldsValue(), id: 0 };
					await UserService.save(values);
					onOk();
					notification.success({ message: "Utilisateur sauvegardé avec succès", });
					form.resetFields();
				} catch (error: any) {
					notification.error({ message: error?.message || "Erreur inconnue!", });
				}
			},
			onCancel() {
			},
		});
	};

	const handleSubmit = () => {
		setLoading(true);
		form.validateFields()
			.then(showConfirm)
			.catch((e) => {
				console.log("Error ", e);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const onFieldsChange = (changed: any, values: any) => { };

	return (
		<Modal
			title="Saisie Utilisateur"
			centered
			open={show}
			styles={{ header: { textAlign: "center" } }}
			width={window.innerWidth * 0.5}
			confirmLoading={loading}
			onCancel={onCancel}
			footer=""
		>
			<Form
				{...formItemLayout}
				initialValues={{
					role: roles[0]?.value,
					sites: [currentSite.id],
				}}
				onFinish={handleSubmit}
				onFinishFailed={onFinishFailed}
				onValuesChange={onFieldsChange}
				autoComplete="off"
				form={form}
			>
				<Form.Item
					label="Nom"
					name="lastName"
					rules={[{ required: true, message: "Nom requis" }]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Prenom"
					name="firstName"
					rules={[{ required: false }]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Login"
					name="login"
					rules={[
						{ required: true, message: "Login requis" },
						() => ({
							async validator(_, value) {
								const res: FetchResponse = await UserService.getUser(value);
								if (res.data.exist) {
									return Promise.reject(new Error(`Ce login n'est plus disponible !!!`));
								}
								return Promise.resolve();
							},
						}),
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Mot de passe"
					name="password"
					rules={[{ required: true, message: "Mot de passe requis", }]}
				>
					<Input.Password />
				</Form.Item>
				<Form.Item
					label="Confirmation de mot de passe"
					name="confirmPassword"
					rules={[
						{ required: true, message: "Confirmation de mot de passe requis" },
						({ getFieldValue }) => ({
							validator(_, value) {
								if (!value || getFieldValue('password') === value) {
									return Promise.resolve();
								}
								return Promise.reject(new Error('Les champs de mot de passe et de confirmation doivent correspondre. Merci de corriger'));
							},
						}),
					]}
				>
					<Input.Password />
				</Form.Item>
				<Form.Item
					label="Role"
					name="role"
					rules={[
						{ required: true, message: "Role requis" },
					]}
				>
					<Radio.Group
						options={roles}
						onChange={onChangeRole}
						value={currentRole} />
				</Form.Item>
				<Form.Item
					label="Site"
					name="sites"
					rules={[
						{ required: true, message: "Site requis" },
					]}
				>
					<Select
						mode="multiple"
						style={{ width: '100%' }}
						placeholder="Sites"
						maxCount={roles.filter((item) => item.value == currentRole)[0]?.name != "manager" ? 1 : undefined}
						options={sites}
					/>
				</Form.Item>
				<Form.Item wrapperCol={{ offset: 16 }}>
					<Flex gap="small">
						<Button type="default" htmlType="button" onClick={onCancel}>Annuler</Button>
						<Button type="primary" htmlType="submit" className="bg-blue">Enregistrer</Button>
					</Flex>
				</Form.Item>
			</Form>

		</Modal>
	);
};
