import { useRef, useState } from "react";
import {
  Space,
  Button,
  Input,
  InputRef,
  TableColumnType,
  Checkbox,
  DatePicker,
} from "antd";
import { CalendarFilled, SearchOutlined } from "@ant-design/icons";
import { FilterDropdownProps } from "antd/es/table/interface";
import Highlighter from "react-highlight-words";
import { Moment } from "moment";
import moment from "moment";
import { dateFormat } from "../utils/string";

export const useColumnFilter = <T,>() => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState<keyof T>();
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: keyof T
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: keyof T,
    type: 'select' | 'search' | 'date' = 'search',
    data: T[] = [],
	  transform?: (value: any) => string,
	  renderFn?: (text: any, record: T) => string,
	  searchIn?: (record: T) => any,
  ): TableColumnType<T> => {
    if(type === 'select'){
      return {
        filters: data.reduce((prev, curr) => {
          const _value =  transform  ? transform(curr[dataIndex]) : curr[dataIndex];
          const found = prev.find((p) => p.value === _value);
          if (!found) {
            prev.push({
              text: _value,
              value: _value,
            });
          }
          return prev;
        }, [] as any[]),
        onFilter: (value, record: any) => new RegExp(value as string, "gi").test(transform  ? transform(record[dataIndex as string]) : record[dataIndex as string]),
        filterDropdown: ({
          close,
          confirm,
          selectedKeys,
          setSelectedKeys,
          clearFilters,
          filters,
        }) => {
          const handleCheckboxChange = (
            checkedValue: string,
            checked: boolean
          ) => {
            if (checked) {
              setSelectedKeys([...selectedKeys, checkedValue]);
            } else {
              setSelectedKeys(
                selectedKeys.filter((key: any) => key !== checkedValue)
              );
            }
          };
          return (
              <Space direction="vertical" style={{ padding: 8, maxHeight: 300, overflowY: 'auto' }}>
                {filters?.map((filter) => (
                  <div key={filter.value as string}>
                    <Checkbox
                      key={filter.value as string}
                      checked={selectedKeys.includes(filter.value as string)}
                      onChange={(e) =>
                        handleCheckboxChange(
                          filter.value as string,
                          e.target.checked
                        )
                      }
                    >
                      {filter.text}
                    </Checkbox>
                  </div>
                ))}
                <Space direction="horizontal">
                  <Button
                    onClick={() => {
                      if(clearFilters){
                        clearFilters()
                      }
                      confirm({ closeDropdown: true });
                    }}
                    style={{ width: "100%" }}
                    size="small"
                  >
                    Réinitialiser
                  </Button>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      confirm({ closeDropdown: true });
                      setSearchText((selectedKeys as string[])[0]);
                      setSearchedColumn(dataIndex);
                    }}
                    style={{ width: "100%" }}
                  >
                    Ok
                  </Button>
                </Space>
            </Space>
          );
        },
      }
    }else if(type === 'search'){
      return {
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
        }: any) => (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={`Rechercher ...`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() =>
                handleSearch(selectedKeys as string[], confirm, dataIndex)
              }
              style={{ marginBottom: 8, display: "block" }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() =>
                  handleSearch(selectedKeys as string[], confirm, dataIndex)
                }
                size="small"
                style={{ width: 90 }}
              >
                Rechercher
              </Button>
              <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
              >
                Réinitialiser
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  setSearchText((selectedKeys as string[])[0]);
                  setSearchedColumn(dataIndex);
                }}
              >
                Filtrer
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  close();
                }}
              >
                Fermer
              </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered: boolean) => (
          <SearchOutlined
            style={{ color: filtered ? "#fff" : "#0000004a" }}
          />
        ),
		  onFilter: (value, record) => {
			  let data: any = record[dataIndex] as any;
			  if (searchIn) {
				  data = searchIn(record) as any;
			  }
			  return (transform ? transform(data) : data)
            .toString()
            .toLowerCase()
				  .includes((value as string).toLowerCase())
		  },
        onFilterDropdownOpenChange: (visible) => {
          if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
          }
        },
		  render: (text, record) =>
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
					  textToHighlight={text ? (renderFn ? renderFn(text.toString(), record) : text.toString()) : ""}
            />
          ) : (
					  text ? (renderFn ? renderFn(text.toString(), record) : text.toString()) : ""
          ),
      }
    }else if(type === 'date'){
      return {
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
        }: any) => (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <DatePicker
              value={selectedKeys[0]}
              onChange={(date) =>{
                setSelectedKeys(date ? [date] : [null])
              }}
              style={{ marginBottom: 8, display: "block" }}
              format="DD/MM/YYYY"
              placeholder="Date ..."
            />
            <Space>
              <Button
                type="primary"
                onClick={() =>
                  handleSearch(selectedKeys as string[], confirm, dataIndex)
                }
                size="small"
                style={{ width: 90 }}
              >
                Ok
              </Button>
              <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
              >
                Réinitialiser
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  close();
                }}
              >
                Fermer
              </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered: boolean) => (
          <CalendarFilled
            style={{ color: filtered ? "#fff" : "#0000004a" }}
          />
        ),
        onFilter: (value, record) =>{
           return (transform ? transform((record[dataIndex] as any)) : (record[dataIndex] as any))
            .toString()
            .toLowerCase()
            .includes(((value as any as Moment).format('YYYY-MM-DD')))
        },
        onFilterDropdownOpenChange: (visible) => {
          if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
          }
        },
        render: (text) =>
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ""}
            />
          ) : (
				  moment(text).format(dateFormat)
			  ),
      }
	  }
	  return {};
  }

  return { getColumnSearchProps };
};
