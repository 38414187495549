import { FetchResponse } from '../models/fetch-response-model';
import { http } from './axios';

export class SiteService {
	public static getAllSites(): Promise<FetchResponse> {
		return http.get('/sites');
	}

	public static getUserSites(): Promise<FetchResponse> {
		return http.get('/sites/user');
	}

	public static setDefaultSite(data: { siteId: number }) {
		return http.post('/sites/user', data);
	}
}
