import axios from 'axios';
import { Auth } from './auth.service';

let http = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	withCredentials: true
});

http.interceptors.request.use(async request => {
	const token = localStorage.getItem('token');
	request.headers['Authorization'] = token ? `Bearer ${token}` : '';
	return request;
});

http.interceptors.response.use(
	async response => {
		return Promise.resolve(response.data);
	},
	async err => {
		const res = err.response;
		res.config._retry = false;
		if (res.status === 401 && !res.config._retry) {

			const refresh = await Auth.refresh();

			res.config.headers.Authorization = `Bearer ${refresh.accessToken}`;

			res.config._retry = true;

			localStorage.setItem('token', refresh.accessToken);

			return axios(res.config).then((response) => response.data);
		}

		if (res.status === 403) {
			await Auth.logout();
		}

		return Promise.reject({ message: res.data?.message || err.message, status: res?.status || err?.status, code: err.code })
	}
);

export { http };