export class Compteur {
	id: number = 0;
	numcompteur: string = "";
	idclient: string = "";
}

export class MeterDetails {
	meterId: number = 0;
	installationDate: Date = new Date();
	meterNumber: string = '';
	idUser: number = 0;
	lastName: string = '';
	firstName: string = '';
	address: string = '';
	district: string = '';
}

export class CustomerMeter {
	branchType: string = "";
	customerId: number = 0;
	id: number = 0;
	installationDate: Date = new Date();
	lastIndex: number = 0;
	meterId: number = 0;
	meterNumber: string = '';
}