import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { Action, reducer } from './reducer';
import { InitialStateInterface, initialState } from './initialState';
import { Auth, ProfileService, SiteService } from '../services';
import { useLocation } from 'react-router-dom';
import { message } from 'antd';
import { FetchResponse, Site } from '../models';

export class ThemeToken {
	blue: string = '';
	green: string = '';
}

export class ThemeType {
	token: ThemeToken = new ThemeToken();
}

interface ContextInterface {
	theme: ThemeType;
	state: InitialStateInterface;
	dispatch: React.Dispatch<Action>;
}

const Context = createContext<ContextInterface>({
	theme: new ThemeType(),
	dispatch: () => { },
	state: initialState,
});

export const Provider = ({ children }: any) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const [messageApi, contextHolder] = message.useMessage();
	const [theme] = useState<ThemeType>(new ThemeType());

	let location = useLocation();

	useEffect(() => {
		async function getProfile() {
			try {
				const profile = await ProfileService.getProfile();
				dispatch({ payload: profile, type: 'SET_USER' });
			} catch (error: any) {
				messageApi.open({
					type: 'error',
					content: `${error?.response?.data?.message}`,
				});
			}
		}
		const getSites = async () => {
			try {
				let result: FetchResponse = await SiteService.getUserSites();
				if (result?.status === "success") {
					const sites: Site[] = result.data;
					if (sites?.length > 0) {
						const defaultSite: Site[] = sites.filter((item) => item.defaultSite);
						if (defaultSite?.length > 0) {
							dispatch({ payload: defaultSite[0], type: 'SET_CURRENTSITE' });
						} else {
							dispatch({ payload: sites[0], type: 'SET_CURRENTSITE' });
						}
					}
				}
			} catch (error: any) {
				messageApi.open({
					type: 'error',
					content: `Erreur de la récuperation du site`,
				});
			}
		}
		if (state.loggedIn && !state.user) {
			getProfile();
			getSites();
		}

		return () => { };
	}, [state.loggedIn, state.user, messageApi]);

	useEffect(() => {
		const token = localStorage.getItem('token') || '';

		async function checkToken() {
			dispatch({ payload: true, type: 'CHECK_TOKEN_LOADING' });
			setTimeout(async () => {
				try {
					await Auth.checkToken(token);
					dispatch({ payload: true, type: 'LOGGED_IN' });
				} catch (error: any) {
				} finally {
					dispatch({ payload: false, type: 'CHECK_TOKEN_LOADING' });
				}
			}, 500);
		}

		if (token && !state.loggedIn) {
			checkToken();
		}

		return () => { };
	}, [location.pathname, state.loggedIn]);

	return (
		<Context.Provider value={{ theme, state, dispatch }}>{contextHolder}{children}</Context.Provider>
	);
};

export const useStore = () => {
	return useContext(Context);
};
