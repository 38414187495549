import { FC, useCallback, useEffect, useState } from "react";
import { Col, Row, Table, TableColumnsType, Space, Button, Input } from "antd";
import { FetchResponse, Site, UserDetails } from "../../../models";
import { SiteService, UserService } from "../../../services";
import { UserAddOutlined } from "@ant-design/icons";
import { useStore } from "../../../store";
import { useColumnFilter } from "../../../hooks/useColumnFilter";
import { UserForm } from "./userForm";

const UserManagerPage: FC = () => {
	const [usersDetails, setUsersDetails] = useState<UserDetails[]>([]);
	const [usersDetailsTmp, setUsersDetailsTmp] = useState<UserDetails[]>([]);
	const [roles, setRoles] = useState<any[]>([]);
	const [sites, setSites] = useState<Site[]>([]);
	// const [focusedUser, setFocusedUser] = useState<UserDetails>(new UserDetails());
	const [loading, setLoading] = useState<boolean>(false);
	const [pageSize, setPageSize] = useState<number>(15);
	const [showUserForm, setShowUserForm] = useState<boolean>(false);

	const { getColumnSearchProps } = useColumnFilter<UserDetails>()

	const { state: { currentSite } } = useStore();

	const fetch = useCallback(async () => {
		if (currentSite.id) {
			try {
				setLoading(true);
				let result: FetchResponse = await UserService.getUserPerSite(currentSite.id);
				if (result?.status === "success") {
					setUsersDetails(result.data);
					setUsersDetailsTmp(result.data);
				}
			} catch (error) {
				console.log(error);
			}
			finally {
				setLoading(false);
			}
		}
	}, [currentSite.id]);

	useEffect(() => {
		const getData = async () => {
			try {
				setLoading(true);
				let result: FetchResponse = await UserService.getUserTypes();
				if (result?.status === "success") {
					setRoles(result.data);
				}
				result = await SiteService.getUserSites();
				if (result?.status === "success") {
					const sites: Site[] = result.data;
					if (sites?.length > 0) {
						setSites(sites);
					} else {
						setSites([]);
					}
				}
			} catch (error) {
				console.log(error);
			}
			finally {
				setLoading(false);
			}
		}
		getData();
	}, []);

	useEffect(() => {
		fetch();
	}, [fetch]);

	const handleChangePageSize = (current: number, newPageSize: number) => {
		setPageSize(newPageSize);
	};

	// Pagination configuration
	const pagination = {
		pageSize,
		showSizeChanger: true,
		onShowSizeChange: handleChangePageSize,
		// Other pagination configurations
	};

	const detailsColumns: TableColumnsType<UserDetails> = [
		{
			title: 'Nom',
			dataIndex: 'lastName',
			filterSearch: true,
			sorter: (a, b) => {
				return a.lastName.localeCompare(b.lastName);
			},
			...getColumnSearchProps('lastName')
		},
		{
			title: 'Prenom',
			dataIndex: 'firstName',
			filterSearch: true,
			sorter: (a, b) => a.firstName.localeCompare(b.firstName),
			...getColumnSearchProps('firstName')
		},
		{
			title: 'Login',
			dataIndex: 'login',
			filterSearch: true,
			sorter: (a, b) => a.login.localeCompare(b.login),
			...getColumnSearchProps('login', 'select', usersDetails)

		},
		{
			title: 'Mail',
			dataIndex: 'mail',
			filterSearch: true,
			sorter: (a, b) => a.mail?.localeCompare(b.mail),
			...getColumnSearchProps('mail', 'select', usersDetails),
		},
		{
			title: 'Role',
			dataIndex: 'role',
			filterSearch: true,
			sorter: (a, b) => a.role.localeCompare(b.role),
		},
	];

	const search = (e: any) => {
		const value = e.target.value;
		const regExp = new RegExp(value, 'gi');
		const filterTable = usersDetailsTmp.filter((o: any) => Object.keys(o).some(k => regExp.test(o[k])));

		setUsersDetails(filterTable)
	};

	return (
		<Space direction="vertical" size={"large"} style={{ display: 'flex' }}>
			<Row justify={"space-between"} align={"middle"}>
				<Col>
					<Input.Search
						placeholder="Rechercher..."
						enterButton
						onChange={search}
					/>
				</Col>
				<Col style={{ display: 'flex', alignItems: 'center' }}>
					<div style={{ marginRight: "20px" }}>Total: <strong>{usersDetails.length}</strong></div>
					<Button
						onClick={() => setShowUserForm(true)}
						icon={<UserAddOutlined />}
					>Ajouter un utilisateur
					</Button>
				</Col>
			</Row>
			{
				showUserForm &&
				<UserForm
					show={showUserForm}
					roles={roles}
					currentUser={new UserDetails()}
					userSites={sites}
					onOk={() => {
						fetch();
						setShowUserForm(false);
					}}
					onCancel={() => {
						setShowUserForm(false);
					}}
				/>
			}
			<Row justify={"center"}>
				<Col span={24}>
					<Table
						columns={detailsColumns}
						rowKey={"id"}
						dataSource={usersDetails}
						size="small"
						loading={loading}
						pagination={pagination}
						scroll={{ y: 585 }}
					/>
				</Col>
			</Row>
		</Space>
	)
};

export default UserManagerPage;