export class Invoice {
	customer!: string;
	meter!: string;
	volume!: number;
	total!: number;
	date!: string;
	lastIndex!: number;
	ref!: string;
	user!:string;
	reportDate!:string;
	district!:string
	id!:number
	pdf!:string
	reporter!:string
	appellation!:string
	payment!:Payment
}

export class Payment{
	id!:number
	date!:string
	proofFile!:string
	paymentMethod!:string
	paymentCancelation!:PaymentCancelation
	cashier!:string
	cashierId!:number
}

export class PaymentCancelation{
	id!:number
	date!:string
	cancelationReason!:string
	cashier!:string
	status!:string
}