import { VolumeProducedDistributed } from "../models";
import { http } from "./axios";

export class VolumeProducedDistributedService {
  public static getInitialIndexs(siteId: number): Promise<VolumeProducedDistributed> {
    return http.get(`/volume-produced-and-distributed/${siteId}`);
  }

  public static saveInitialIndex(index: VolumeProducedDistributed): Promise<VolumeProducedDistributed> {
    return http.post(`/volume-produced-and-distributed`, index);
  }
}
