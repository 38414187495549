import { VolumeProducedDistributed } from "../models";
import { http } from "./axios";

export class VolumeProducedDistributedService {
  public static geIndexes(siteId: number, params = {}): Promise<VolumeProducedDistributed[]> {
    return http.get(`/volume-produced-and-distributed/${siteId}`, {params});
  }

  public static saveIndex(index: VolumeProducedDistributed): Promise<VolumeProducedDistributed> {
    return http.post(`/volume-produced-and-distributed`, index);
  }

  public static deleteIndex(siteId:number,indexId:number,): Promise<any> {
    return http.delete(`/volume-produced-and-distributed/${siteId}/${indexId}`);
  }
}
