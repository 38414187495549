import { FC, useCallback, useEffect, useState } from "react";
import dayjs from 'dayjs';
import { Col, Row, DatePicker, Form, Space, FormProps, TimeRangePickerProps, message, Spin, Flex } from "antd";
import { FetchResponse } from "../../../models";
import moment from "moment";
import { useStore } from "../../../store";
import CanvasJSReact from '@canvasjs/react-charts';
import { DashboardService } from "../../../services";
import { InnerCard } from "../../../components";
import { FilterOutlined } from "@ant-design/icons";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const { RangePicker } = DatePicker;

class DataType {
	y: number = 0;
	label: string = "";
}

const defaultDate: number = -365;

const DashboardPage: FC = () => {
	// const [reportState, setReportState] = useState<{ [key: string]: any[] }>({});
	const [subscribers, setSubscribers] = useState<{ [key: string]: any[] }>({ "menage": [{ y: 0 }] });
	const [customerPerDistrict, setCustomerPerDistrict] = useState<DataType[]>([]);
	const [volumeTotal, setVolumeTotal] = useState<{ [key: string]: any[] }>({});
	const [filter, setFilter] = useState<FilterType>(Object.assign(new FilterType(), { periode: [dayjs().add(defaultDate, 'd'), dayjs()] }));
	const { state: { currentSite } } = useStore();
	const [messageApi, contextHolder] = message.useMessage();
	const [loading, setLoading] = useState<boolean>(false);

	const getRangeDate = useCallback(() => {
		let fromDate: string = "";
		let toDate: string = "";
		try {
			if (filter.periode) {
				if (filter.periode[0]) {
					fromDate = filter.periode[0] ? moment(filter.periode[0].$d).format('YYYY-MM-DD') : '';
				}
				if (filter.periode[1]) {
					toDate = filter.periode[1] ? moment(filter.periode[1].$d).format('YYYY-MM-DD') : '';
				}
			}
		} catch (e: any) {
			fromDate = moment(dayjs().add(defaultDate, 'd').toString()).format('YYYY-MM-DD');
			toDate = moment(dayjs().toString()).format('YYYY-MM-DD');
		}
		return [fromDate, toDate];
	}, [filter.periode]);

	useEffect(() => {
		const fetch = async () => {
			try {
				let [fromDate, toDate]: string[] = getRangeDate();
				if (currentSite.id !== 0) {
					let result: FetchResponse;
					setLoading(true);
					// Customers per district
					result = await DashboardService.getCustomersPerDistrict(fromDate, toDate, currentSite.id);
					if (result?.status === "success") {
						const total = result.data.reduce((acc: number, item: any) => +item.total + acc, 0);
						const data = result.data.map((item: any) => {
							return {
								count: item.total,
								label: item.district,
								y: (100 * item.total / total).toFixed(2),
							};
						});
						setCustomerPerDistrict(data);
					}
					// Report state
					// result = await DashboardService.getReportState(fromDate, toDate, currentSite.id);
					// if (result?.status === "success") {
					// 	setReportState(result.data);
					// }
					// Volume total
					result = await DashboardService.getVolumeTotal(fromDate, toDate, currentSite.id);
					if (result?.status === "success") {
						// console.log("res data", result.data);
						setVolumeTotal(result.data);
					}
					// Nombre d'abonnees
					result = await DashboardService.getSubscribers(fromDate, toDate, currentSite.id);
					if (result?.status === "success") {
						// console.log("res data", result.data);
						setSubscribers(result.data);
					}
				} else {
					messageApi.open({
						type: 'error',
						content: 'Erreur de la recuperation du site',
					});
				}
			} catch (e: any) {
				console.log("Fetch data: ", e.message);
			} finally {
				setLoading(false);
			}
		};
		fetch();
	}, [currentSite.id, messageApi, getRangeDate]);

	const onValuesChange: FormProps<FilterType>["onValuesChange"] = async (_, filter) => {
		try {
			setFilter(filter);
		} catch (e) {
			console.info("Report page error: ", e);
		}
	};

	const totalPerDistrictOpt = {
		theme: "light2",
		title: {
			text: "Client par district",
			fontSize: 24,
		},
		subtitles: [{
			text: "Total: " + customerPerDistrict.reduce((acc: number, item: any) => +item.count + acc, 0),
			fontSize: 16,
		}],
		data: [{
			type: "pie",
			showInLegend: true,
			legendText: "{label}",
			toolTipContent: "{label}: <strong>{count}</strong>",
			indexLabel: "{y}%",
			indexLabelPlacement: "inside",
			dataPoints: customerPerDistrict
		}]
	}

	const totalConsumedOpt = {
		theme: "light2",
		title: {
			text: "Volume encaissé",
			fontSize: 24,
		},
		subtitles: [{
			text: "Volume facturé"
		}],
		toolTip: {
			shared: true
		},
		data: [
			{
				type: "column",
				name: "Volume facturé",
				// indexLabel: "{y}",
				yValueFormatString: "#,##0",
				dataPoints: volumeTotal["facture"]
			},
			{
				type: "splineArea",
				name: "Volume encaissé",
				// indexLabel: "{y}",
				yValueFormatString: "#,##0",
				dataPoints: volumeTotal["releve"]
			},
		]
	}

	// const reportStatusOpt = {
	// 	title: {
	// 		text: "Etat des relevés",
	// 		fontSize: 24,
	// 	},
	// 	toolTip: {
	// 		shared: true
	// 	},
	// 	legend: {
	// 		verticalAlign: "top"
	// 	},
	// 	axisY: {
	// 		suffix: "%"
	// 	},
	// 	data: [
	// 		{
	// 			type: "stackedBar100",
	// 			color: "#9b0059",
	// 			name: "Non relevé",
	// 			showInLegend: true,
	// 			indexLabel: "{y}",
	// 			indexLabelFontColor: "white",
	// 			yValueFormatString: "#,##0' %'",
	// 			dataPoints: reportState["rest"]
	// 		},
	// 		{
	// 			type: "stackedBar100",
	// 			color: "#9bbbaa",
	// 			name: "Relevé",
	// 			showInLegend: true,
	// 			indexLabel: "{y}",
	// 			indexLabelFontColor: "white",
	// 			yValueFormatString: "#,##0' %'",
	// 			dataPoints: reportState["reported"]
	// 		},
	// 		{
	// 			type: "stackedBar100",
	// 			color: "#9bbb59",
	// 			name: "Facturé",
	// 			showInLegend: true,
	// 			indexLabel: "{y}",
	// 			indexLabelFontColor: "white",
	// 			yValueFormatString: "#,##0' %'",
	// 			dataPoints: reportState["billed"]
	// 		},
	// 	]
	// }

	const subscriberOpt = {
		theme: "light2",
		title: {
			text: "Nombre de clients",
			fontSize: 24,
		},
		subtitles: [{
			text: "Nombre de ménages"
		}],
		toolTip: {
			shared: true
		},
		data: [
			{
				type: "splineArea",
				name: "Nombre de ménages",
				showInLegend: true,
				yValueFormatString: "#,##0",
				dataPoints: subscribers["menage"]
			},
			{
				type: "splineArea",
				name: "Nombre de clients",
				showInLegend: true,
				yValueFormatString: "#,##0",
				dataPoints: subscribers["abonne"]
			}
		]
	};

	return (
		<Spin size="large" spinning={loading}>
			<Space direction="vertical" size={"small"} style={{ display: 'flex' }}>
				{contextHolder}
				<InnerCard
					title={"Filtres"}
					icon={<FilterOutlined />}
				>
					<Form
						name="report_filter"
						onValuesChange={onValuesChange}
						style={{ maxWidth: 'none' }}
					>
						<Row gutter={24} justify={"start"}>
							<Col span={8}>
						<Form.Item
									label=""
							name={"periode"}
							initialValue={[dayjs().add(defaultDate, 'd'), dayjs()]}
									style={{ width: '100%', margin: 0 }}
						>
							<RangePicker
								style={{ width: '100%' }}
								picker="month"
								presets={rangePresets}
								placeholder={["01-01-1970", "Aujourd'hui"]}
								needConfirm={true}
								allowEmpty={[false, true]}
								name="periode"
								format={dateFormat}
							/>
						</Form.Item>
							</Col>
						</Row>
					</Form>
				</InnerCard>
				<Row justify={"center"}>
					<Col span={24}>
						{subscribers["menage"][0].y == 0 ?
							<Flex className="empty-data" justify={"center"} align={"center"}>
								<span style={{ width: '50%', textAlign: 'center' }}>Pour afficher le nombre des abonnés, veuillez contacter votre <b>administrateur</b> afin de renseigner le <b>nombre total de ménage</b> sur le site</span>
							</Flex>
							: <CanvasJSChart options={subscriberOpt} />}
					</Col>
				</Row>
				<Row justify={"center"}>
					<Col span={12} style={{ paddingRight: '10px' }}>
						{customerPerDistrict.length ? <CanvasJSChart options={totalPerDistrictOpt} /> : <></>}
					</Col>
					<Col span={12}>
						<CanvasJSChart options={totalConsumedOpt} />
					</Col>
				</Row>
			</Space>
		</Spin>
	)
};

const rangePresets: TimeRangePickerProps['presets'] = [
	{ label: 'Mois actuel', value: [dayjs().startOf('month'), dayjs().endOf('month')] },
	{ label: '3 dernier mois', value: [dayjs().add(-2, 'M'), dayjs()] },
	{ label: '6 dernier mois', value: [dayjs().add(-5, 'M'), dayjs()] },
	{ label: '12 dernier mois', value: [dayjs().add(-11, 'M'), dayjs()] },
];

const dateFormat = 'MMMM YYYY';

class FilterType {
	periode: any[] = [];
}

export default DashboardPage;