import React, { ReactNode, FC } from "react";
import { Card } from "antd";

const title: { [key: string]: string } = {
	PENDING: "DEMANDE D'ANNULATION EN COURS",
	ACCEPTED: "DEMANDE D'ANNULATION ACCEPTÉE",
	REFUSED: "REFUSÉE",
};

interface ITypePros {
	children: ReactNode;
	icon: ReactNode;
	title: string;
}

export const InnerCard: FC<ITypePros> = ({ children, icon, title }) => {
	return (
		<Card
			type="inner"
			title={
				<div>
					<span style={{ marginRight: 10 }}>
						{icon}
					</span>
					{title}
				</div>
			}
		>
			{children}
		</Card>
	);
}
