export class ReportDetails {
	id: number = 0;
	reportDate: string = "";
	currentIndex: number = 0;
	image: string = "";
	userId: number = 0;
	customerMeterId: number = 0;
	number: string = "";
	site_id: number = 0;
	district_id: number = 0;
	customer_id: number = 0;
	lastName: string = "";
	firstName: string = "";
	user_lastname: string = "";
	user_firstname: string = "";
	site_label: string = "";
	district_label: string = "";
	customer_invoice_id!: number;
	customer_invoice_pdf!:string
}